//
// user-variables.scss
// Use this to override Bootstrap and Silicon variables
//

// Example of a variable override to change Silicon background color
// Remove the "//" to comment it in and see it in action!
// $body-bg: #f3f6ff;

$font-family-sans-serif: "halyard-display", sans-serif !default;

.major-style-font-override{
  font-family: "Aboreto", serif;
}

